module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"llcooltr8-starter-default","short_name":"llcooltr8","start_url":"/","background_color":"##111F51","theme_color":"##111F51","display":"minimal-ui","icon":"src/images/tr8.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
